import BlockContent from '@sanity/block-content-to-react';
import React, { useState } from 'react';

import serializers from '../../serializers';
import { RawPortableText } from '../../types/types';
import { clsx } from '../../utils/utils';
import Accordion from './Accordion';
import Button from './Button';
import * as styles from './TabbedCardList.module.scss';

export interface Tab {
  title: string;
  text: RawPortableText;
  toggleButton?: {
    id: string;
    initialState?: boolean;
    disabled?: boolean;
  };
}

export type TabbedCardListProps = {
  tabs: Array<Tab>;
  buttons?: Array<{
    title: string;
    onButtonClick: (togglesStates: Record<string, boolean>) => void;
    checkEnabled?: (togglesStates: Record<string, boolean>) => boolean;
  }>;
  className?: string;
};

const TabbedCardList = ({ tabs, buttons, className }: TabbedCardListProps): React.ReactElement => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [togglesStates, setTogglesStates] = useState<Record<string, boolean>>(
    tabs.reduce(
      (states, { toggleButton }) =>
        toggleButton
          ? { ...states, [toggleButton.id]: toggleButton.initialState || false }
          : states,
      {},
    ),
  );

  const handleToggle = (toggleId: string) => {
    setTogglesStates(prevStates => {
      const newState = { ...prevStates };
      newState[toggleId] = !newState[toggleId];
      return newState;
    });
  };

  function renderToggleButton(toggleButton: NonNullable<Tab['toggleButton']>, label: string) {
    return (
      <div
        className={clsx(
          styles.toggleContainer,
          toggleButton.disabled && styles.disabled,
          togglesStates[toggleButton.id] && styles.enabled,
        )}
      >
        <label className={clsx(styles.toggle)}>
          <span className={styles.visuallyHidden}>{label}</span>
          <input
            disabled={toggleButton.disabled}
            type="checkbox"
            checked={togglesStates[toggleButton.id]}
            onChange={() => {
              handleToggle(toggleButton.id);
            }}
          />
          <span className={styles.slider}></span>
        </label>
        <span className={styles.toggleLabel}>
          {togglesStates[toggleButton.id] ? 'Enabled' : 'Disabled'}
        </span>
      </div>
    );
  }

  return (
    <section className={clsx(styles.sectionContainer, className)}>
      <div className={clsx(styles.section)}>
        <div className={styles.tabbedListContainerDesktop}>
          <div className={styles.tabsTitleContainer}>
            {tabs.map((tab, i) => (
              <nav
                className={clsx(styles.tabTitle, activeTabIndex === i && styles.active)}
                key={i}
                onClick={() => {
                  setActiveTabIndex(i);
                }}
              >
                {tab.title}
              </nav>
            ))}
          </div>
          <div className={styles.tabsContainer}>
            {tabs.map((tab, i) => (
              <div
                className={clsx(styles.tabsTextContainer, activeTabIndex === i && styles.active)}
                key={i}
              >
                <h3 className={styles.textContainerTitle}>{tab.title}</h3>
                <BlockContent
                  className={styles.text}
                  blocks={tab.text}
                  renderContainerOnSingleChild
                  serializers={serializers}
                />
                {tab.toggleButton &&
                  renderToggleButton(tab.toggleButton, `Enable or disable ${tab.title}`)}
              </div>
            ))}
            {buttons && <div className={styles.divider}></div>}
            {buttons && (
              <div className={styles.buttonsContainer}>
                {buttons.map((button, i) => (
                  <Button
                    key={i}
                    color="#02443C"
                    disabled={button.checkEnabled ? !button.checkEnabled(togglesStates) : false}
                    onClick={() => {
                      button.onButtonClick(togglesStates);
                    }}
                  >
                    {button.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.tabbedListContainerMobile}>
          <div className={styles.accordionsContainer}>
            {tabs.map((tab, i) => (
              <Accordion key={i} title={tab.title} startsOpen>
                <BlockContent
                  className={styles.text}
                  blocks={tab.text}
                  renderContainerOnSingleChild
                  serializers={serializers}
                />
                {tab.toggleButton &&
                  renderToggleButton(tab.toggleButton, `Enable or disable ${tab.title}`)}
              </Accordion>
            ))}
          </div>
          {buttons && <div className={styles.divider}></div>}
          {buttons && (
            <div className={styles.buttonsContainer}>
              {buttons.map((button, i) => (
                <Button
                  key={i}
                  color="#02443C"
                  disabled={button.checkEnabled ? !button.checkEnabled(togglesStates) : false}
                  onClick={() => {
                    button.onButtonClick(togglesStates);
                  }}
                >
                  {button.title}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TabbedCardList;
